export enum PageComponentEnum {
	CAROUSEL = 'carousel',
	SEARCH_BAR = 'search_bar',
	ADVERTS = 'adverts',
	HIGHLIGHTED_BLOCK = 'highlighted_block',
	IMAGE_BOX = 'image_box',
	ABOUT_US = 'abouts_us',
	FAQS = 'faqs',
	PARTNERS = 'partners',
	NEWS = 'news',
	FORMS = 'forms',
	SIMPLE_TEXT = 'simple_text',
	VIDEO = 'video',
	MAP = 'map',
	TEAM = 'team',
	SPLITTED_IMAGES = 'splitted_images',
}
